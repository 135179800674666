import React from 'react';
import WaistManual from "../../components/WaistManual";
import useWaistENImages from "../../hooks/useWaistENImages";

const locale = "en";
export default () => {
	const images = useWaistENImages();
	return (
		<>
			<WaistManual locale={locale} images={images}/>
		</>
	);
}
